import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import './home.styles.scss';
import { useRef, useState } from 'react';

export const Home = () => {
    const { innerWidth: width } = window;
    const [muted, setMuted] = useState(true);

    const videoRef = useRef(null);

    const muteVideo = (mute: boolean) => {
        setMuted(mute);
    };

    return ( 
        <div className="home" id='home'>    
            <div className="home__icon">
                {muted ?
                    <FontAwesomeIcon icon={faVolumeMute} onClick={() => muteVideo(false)} /> :
                    <FontAwesomeIcon icon={faVolumeHigh} onClick={() => muteVideo(true)} />
                }
            </div>
            {width > 600 ?            
                <video className="home__background" autoPlay muted={muted} ref={videoRef}>
                    <source src={require("src/assets/video/home-video-desktop.mp4")} type="video/mp4" />
                </video> :    
                <video className="home__background" autoPlay muted={muted} ref={videoRef}>
                    <source src={require("src/assets/video/home-video-mobile.mp4")} type="video/mp4" />
                </video>
            }     
        </div>
    )
}