import { Button } from 'src/shared/button/button.component';
import 'src/assets/scss/headers.scss';
import './work.styles.scss';
import 'src/assets/scss/links.scss'; 

export const Work = () => {
    return (
        <>
            <h1 className="title-h1">WORK</h1>
            <div className='work' id='work'>
                <div className='work__images'>
                    <a href="https://runiverse.world/" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/runes.jpg')} alt="Runiverse" />
                    </a>
                    <a href="https://www.youtube.com/watch?v=g9qXi6DjgWk" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/SELENE.jpg')} alt="SELENE" />
                    </a>
                    <a href="https://www.myvegas.com/" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/Galaxy.jpg')} alt="Galaxy" />
                    </a>
                    <a href="https://www.3cherry.com/games/big-papas-burger/" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/burger.jpg')} alt="Burger" />
                    </a>
                    <a href="https://store.steampowered.com/app/1829200/Kandra_The_Moonwalker/?l=spanish" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/kandra.jpg')} alt="Kandra" />
                    </a>
                    <a href="https://www.3cherry.com/el-dorado-ritual-demo/" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/Dorado.jpg')} alt="Dorado" />
                    </a>
                    <a href="https://www.3cherry.com/steamstorm-demo/" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/Storm.jpg')} alt="Storm" />
                    </a>
                    <a href="https://ventekgames.itch.io/necrognomicon" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/necro.jpg')} alt="Necrognomicon" />
                    </a>
                    <a href="https://nehangame.itch.io/nehan" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/nehan.jpg')} alt="Nehan" />
                    </a>
                    <a href="https://vimeo.com/manage/videos/836066417" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/rez.jpg')} alt="Rez" />
                    </a>
                    <a href="https://www.youtube.com/watch?v=9lLiw9HnQOk" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/jurassic.jpg')} alt="Jurassic" />
                    </a>
                    <a href="https://www.youtube.com/watch?v=wwTS16-bhMA" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/Scream.jpg')} alt="Scream" />
                    </a>                 
                    <a href="https://www.youtube.com/watch?v=G-k_9lR3MWw" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/madrid.jpg')} alt="Madrid" />
                    </a>
                    <a href="https://www.youtube.com/watch?v=_LU5fzEd59A" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/genesis.jpg')} alt="Berlin" />
                    </a>
                    <a href="https://www.youtube.com/watch?v=4oWnOXKgUL4" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/tierra.jpg')} alt="Somos Tierra" />
                    </a>
                    <a href="https://www.facebook.com/villafrancaturismo/videos/588853045791348" target='_blank'>
                        <img className='work__img' src={require('src/assets/images/azafran.jpg')} alt="Azafrán" />
                    </a>
                </div>
                <section className='work__text'>
                    <p>PlayStation, PC, Steam, Android... I have worked with many studios and companies where we have developed different types of video games (AAA, mmorpg, battle royale...) for different platforms. <br/>I have also worked with young and senior film directors and other media companies to create AWESOME SOUNDTRACKS!</p>
                </section>
                
            </div>
        </>
    );
};