import './button.styles.scss';

type ButtonProps = {
    label: string;
    type?: 'button' | 'submit';
    icon?: string;
    onClick?: () => void;
}

export const Button = ({ label, type='button', icon, onClick }: ButtonProps) => {
    return (
        <button className="button" onClick={onClick}>{label}</button>
    )
};