import { Button } from 'src/shared/button/button.component';
import 'src/assets/scss/headers.scss';
import './contact.styles.scss';
import 'src/assets/scss/links.scss'; 
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTwitter, } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';

const formEndpoint = 'https://public.herotofu.com/v1/da814dd0-c577-11ec-a557-034a17e2da28';

type Contact = {
    email: string;
    message: string;
}

export const Contact = () => {
    const [formSent, setFormSent] = useState(false);

    const validationSchema = yup.object({
        email: yup.string().required().matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/),
        message: yup.string().required(),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any, event: any) => {
        event.preventDefault();
        
        fetch(formEndpoint, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => {
                if (response.status === 200) {
                    setFormSent(true);
                }
        }).catch((err) => {});
    };

    return (
        <>
            <h1 className="title-h1">CONTACT</h1>
            <div className='contact__background' id='contact'>
                <img src={require('src/assets/images/contact.png')} alt="Contact img" />
                <div className="contact__form">
                    <h2>LET'S <span>CREATE</span> SOMETHING NEW</h2>
                    <div className='contact__container'>
                        <div className='contact__social'>
                            <p> <FontAwesomeIcon icon={faEnvelope} /> carlosdlfmusic@gmail.com </p>
                            <p> <FontAwesomeIcon icon={faPhone} /> +34 609 961 952 </p>
                            <div className='icons'>
                                <a href="https://twitter.com/carlostrombon7?lang=es" target='_blank'>
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                                <a href="https://www.facebook.com/carlosDLFMusic" target='_blank'>
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a href="https://es.linkedin.com/in/carlos-de-la-fuente-lucas-torres-3a01881ab" target='_blank'>
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                                <a href="https://www.instagram.com/carlosdlfmusic/" target='_blank'>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </div>

                        </div>
                        {!formSent ? <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="contact__form--input">
                                <label>Email*</label>
                                <input className={errors.email ? 'error' : ''} {...register("email", { required: true })} />
                            </div>
                            <div className="contact__form--input">
                                <label>Message*</label>
                                <textarea className={errors.message ? 'error' : ''} rows={4} {...register("message", { required: true })} />
                            </div>                            
                            <Button label="Send" type="submit" />
                        </form> : <p className='contact__sent-form'>Your message has been successfully sent. <br/> <span className='big'>I won't take long to answer! </span></p>}
                    </div>
                </div>
            </div>
            
        </>
    );
};