import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home/home.component';
import { Menu } from './shared/menu/menu.component';
import { Music } from './pages/music/music.component';
import { Work } from './pages/work/work.component';
import { About } from './pages/about/about.component';
import { Contact } from './pages/contact/contact.component';
import { Footer } from './pages/footer/footer.component';


function App() {
  return (
    <div className="app">
      <Menu />
      <div className="app__content">
        <Home />
        <Music />
        <Work />
        <About />
        <Contact />
        <Footer />
      </div>
      {/* <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/music" element={<Music />} />
      </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
