import { Button } from 'src/shared/button/button.component';
import 'src/assets/scss/headers.scss';
import './music.styles.scss';
import 'src/assets/scss/links.scss'; 

export const Music = () => {
    return (
        <>
            <h1 className="title-h1">MUSIC</h1>
            <div className='music' id='music'>
                <img className="music__img" src={require('src/assets/images/header-music.png')} alt="Music Img" />
                <div className='music__content'>
                    <iframe 
                        className="music__spotify"
                        src="https://open.spotify.com/embed/artist/1WOxFq13KCqnZpB95ArCoA?utm_source=generator&theme=0" 
                        width="40%" 
                        height="380" 
                        frameBorder="0"  
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
                    </iframe>
                    <section className="music__description">
                        <p>Epic, powerfull, cozy, sweet...Together we can create everything you need for your projects! <br/> Listen here to all my musical news and visit my Spotify Artist profile if you want to stay tuned. <br/> You can also visit my <a className='link' href='https://www.youtube.com/c/CarlosdelaFuentecompositor' target='_blank'> Youtube official Channel </a> where I post videos and work I do for media. <br/> If you need some licenses of my music you can visit my personal page at <a className='link' href='https://www.thestudiorev.com/artists/carlos-de-la-fuente-lucas-torres' target='_blank'> Studio Rev </a> or if it's better for you, contact me! </p>
                        <Button label="See in Spotify" onClick={() => window.open('https://open.spotify.com/artist/1WOxFq13KCqnZpB95ArCoA?si=aL6rzZS6SF2kFZakzGm66Q', '_blank')} />
                    </section>
                </div>
            </div>
        </>
    );
};