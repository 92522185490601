import { Button } from 'src/shared/button/button.component';
import 'src/assets/scss/headers.scss';
import './about.styles.scss';
import 'src/assets/scss/links.scss'; 
import AliceCarousel from 'react-alice-carousel';
import { DragEventHandler, EventHandler, ReactEventHandler } from 'react';


export const About = () => {
    const handleDragStart = (e: any) => e.preventDefault();

    const items = [
        <div><img src={require('src/assets/images/stafford.jpg')} onDragStart={handleDragStart} role="presentation" /></div>,
        <div><img src={require('src/assets/images/paris.jpg')} onDragStart={handleDragStart} role="presentation" /></div>,
        <div><img src={require('src/assets/images/earth.png')} onDragStart={handleDragStart} role="presentation" /></div>,
        <div><img src={require('src/assets/images/angeles.jpg')} onDragStart={handleDragStart} role="presentation" /></div>,
        <div><img src={require('src/assets/images/akademia.jpg')} onDragStart={handleDragStart} role="presentation" /></div>,
        <div><img src={require('src/assets/images/budapest.jpg')} onDragStart={handleDragStart} role="presentation" /></div>,
        <div><img src={require('src/assets/images/berlin.jpg')} onDragStart={handleDragStart} role="presentation" /></div>,
    ];

    const numberOfItems = {
        0: {
            items: 1,
        },
        700: {
            items: 2
        },
        900: {
            items: 3
        }
    };

    return (
        <>
            <h1 className="title-h1">ABOUT</h1>
            <div className='about' id='about'>
                <img className='about__img' src={require('src/assets/images/aboutfoto.png')} alt="Carlos DLF" />
                <p className='about__text'> Hi! My name is Carlos de la Fuente Lucas-Torres, I'm a freelance Composer and Sound Designer for various media including TV, Films and videogames. A musician since the age of 7, I'm a composer recognized with national and international awards. Included among these are <i>The Akademia Music Awards (Los Angeles), Paris Play Film Festival (Paris), Stafford Film Festival (Stafford), Global Music Awards (California), Berlin International Film Scoring Competition (Berlin)</i> and  many more...</p>
                <a className="about__cv" href={require('src/assets/resources/cv-en.pdf')} target="_blank">Full CV</a>
                <div className='about__reel'>
                    <div className='about__sound'>
                        <h2>SOUND DESIGNER</h2>
                        <p>I have more than three years of experience as a Sound Designer, I really enjoy making noise! Currently, I record every sounds at my home studio (foley and sounds) and I like travelling and walking through different places to make field recordings. Zoom, Tascam, AKG, Steinberg... have become my best friends! <br/> Take a look at my Demo Reel.</p>
                        <Button label="Demo Reel" onClick={() => window.open('https://vimeo.com/622201928', '_blank')} />
                    </div>
                    <div className='about__composer'>
                        <h2>COMPOSER</h2>
                        <p>As a Composer, I have more than ten years of experience working with <i>Wind Band, Symphony Orchestra, Brass band, Street band,</i> etc. I can make your musical dreams come true, whatever you want, working on the best DAWS and Musical Notation Programs (Logic, Cubase, Ableton, Sibelius...) <br/> Take a look at my Demo Reel. </p>
                        <Button label="Demo Reel" onClick={() => window.open('https://vimeo.com/529431605', '_blank')} />
                    </div>
                </div>

                <p>These are some of the awards that I've won in the last years</p>
                <AliceCarousel 
                    mouseTracking items={items}
                    responsive={numberOfItems}
                    disableButtonsControls={true}
                    autoHeight={true}
                    infinite={true}
                />

                <p>... and these some CLIENTS I work with</p>
                <div className='about__clients'>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/logoayto.png')} alt="Ayuntamiento Campo de Criptana" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/aytovilla.png')} alt="Ayuntamiento Villafranca" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/gc.png')} alt="GameCoder Studios" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/fly.png')} alt="Flyleaf Studios" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/ventek.png')} alt="Ventek Games" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/cuatro.png')} alt="Cuatro Gotes" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/rtpa.png')} alt="RTPA" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/titi.png')} alt="Titi Records" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/triple.png')} alt="Triple Cherry" />
                    </div>
                    <div className='about__clients--img'>
                        <img src={require('src/assets/images/LOGOS/bisonic.png')} alt="Bisonic" />
                    </div>
                    
                </div>
                <p>Would you like to be the next?</p>
                
                
                
            </div>
        </>
    );
};