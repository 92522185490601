import { useEffect, useState } from 'react';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import './menu.styles.scss';
import { start } from 'repl';



export const Menu = () => {
    const { innerWidth: width } = window;
    const [showMenu, setShowMenu] = useState(false);
    const [menuHasBackground, setMenuHasBackground] = useState(false);

    const imageTransition = useSpring({
      opacity: showMenu ? 1 : 0,
      config: config.default,
    });

    const listTransition = useSpring({
      opacity: showMenu ? 1 : 0,
      config: config.default,
      delay: 400
    });    

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
    }, []);

    const listenToScroll = () => {
        const windowWidth = window.innerWidth;
        const bgLimit = windowWidth > 750 ? (windowWidth/1.8) : (windowWidth/0.9);
        setMenuHasBackground(window.pageYOffset > bgLimit);
    };

    const navigate = (id:string) => { document.getElementById(id)?.scrollIntoView({behavior:'smooth'});
    setShowMenu(false)}

       
    const menuList = () => (
        <ul className="menu__list">
            <li onClick={() => navigate('home')}>Home</li>
            <li onClick={() => navigate('music')}>Music</li>
            <li onClick={() => navigate('work')}>Work</li>
            <li onClick={() => navigate('about')}>About</li>
            <li onClick={() => navigate('contact')}>Contact</li>
        </ul>
    );

    return (
        <div className={`menu ${menuHasBackground ? 'menu--background' : ''}`}>  
            {showMenu ? <animated.span style={imageTransition} className="menu__bg-image--mobile" /> : null}        
            <img className="menu__logo" src={require('src/assets/images/LOGOS/logo-white.png')} alt="logo" />
            <div className='menu--desktop'>{menuList()}</div>
            <div className="menu--mobile">                
                <button className={`hamburger-menu ${showMenu ? 'focus' : ''}`} onClick={() => setShowMenu(!showMenu)}>
                    <span className="burger" />
                    <span className="burger" />
                    <span className="burger" />
                </button>
                <animated.span style={listTransition}>{menuList()}</animated.span>
            </div>
        </div>
    );
};